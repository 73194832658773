import React from "react";
import { object } from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Lightbox } from "react-modal-image";
import { Layout } from "../components/";

const GalleryPage = ({ pageContext }) => {
    const [imageIndex, setImageIndex] = React.useState(undefined);
    let i = 0;

    return (
        <>
            <Layout>
                <div className="mx-auto my-10 mt-60 sm:mt-40 prose lg:prose-lg max-w-100ch">
                    <h1>Galeria</h1>
                    {Object.values(pageContext.photos).length === 0 ? (
                        <span className="text-xl font-bold">
                            Jeszcze nic tu nie ma, zapraszamy wkrótce
                            {" :)"}
                        </span>
                    ) : (
                        <>
                            <div className="grid auto-grid gap-4">
                                {pageContext.photos.map(photo => (
                                    <>
                                        {!photo.directus_files_id ? (
                                            <></>
                                        ) : (
                                            <figure
                                                className="gallery-tile rounded-2xl !m-0"
                                                onClick={() => {
                                                    setImageIndex(
                                                        photo.directus_files_id
                                                            .id,
                                                    );
                                                }}
                                                key={i++}
                                            >
                                                <LazyLoadImage
                                                    className="gallery-img hover:saturate !m-0"
                                                    src={`https://api.oze.zsi.kielce.pl/assets/${photo.directus_files_id.id}?quality=50`}
                                                    effect="blur"
                                                />
                                            </figure>
                                        )}
                                    </>
                                ))}
                            </div>
                            {imageIndex !== undefined && (
                                <Lightbox
                                    hideDownload={true}
                                    large={`https://api.oze.zsi.kielce.pl/assets/${imageIndex}`}
                                    onClose={() => {
                                        setImageIndex(undefined);
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
            </Layout>
        </>
    );
};

GalleryPage.propTypes = {
    pageContext: object,
};

export default GalleryPage;
